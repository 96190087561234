export { default as Ban } from './ico_ban.svg';
export { default as Bars } from './ico_bars.svg';
export { default as CartPlus } from './ico_cart-plus.svg';
export { default as Cart } from './ico_cart.svg';
export { default as CheckCircle } from './ico_check-circle.svg';
export { default as CheckCircleSolid } from './ico_check-circle--solid.svg';
export { default as Check } from './ico_check.svg';
export { default as Chevron } from './ico_chevron.svg';
export { default as Circle } from './ico_circle.svg';
export { default as ClickCollect } from './ico_click-collect.svg';
export { default as Clock } from './ico_clock.svg';
export { default as Download } from './ico_download.svg';
export { default as Edit } from './ico_edit.svg';
export { default as Envelope } from './ico_envelope.svg';
export { default as ExclamationCircle } from './ico_exclamation-circle.svg';
export { default as EyeSlash } from './ico_eye-slash.svg';
export { default as Eye } from './ico_eye.svg';
export { default as Filter } from './ico_filter.svg';
export { default as HeartFull } from './ico_heart-full.svg';
export { default as Heart } from './ico_heart.svg';
export { default as InfoCircle } from './ico_info-circle.svg';
export { default as List } from './ico_list.svg';
export { default as Location } from './ico_location.svg';
export { default as Logo } from './ico_logo.svg';
export { default as MapMarkerAlt } from './ico_map-marker-alt.svg';
export { default as MenuProduct } from './ico_menu-product.svg';
export { default as MinusCircle } from './ico_minus-circle.svg';
export { default as Mobile } from './ico_mobile.svg';
export { default as PlusCircle } from './ico_plus-circle.svg';
export { default as Plus } from './ico_plus.svg';
export { default as Qrcode } from './ico_qrcode.svg';
export { default as Redo } from './ico_redo.svg';
export { default as Search } from './ico_search.svg';
export { default as Share } from './ico_share.svg';
export { default as Square } from './ico_square.svg';
export { default as SquareChecked } from './ico_square-checked.svg';
export { default as StarFull } from './ico_star-full.svg';
export { default as Star } from './ico_star.svg';
export { default as Store } from './ico_store.svg';
export { default as TimesCircle } from './ico_times-circle.svg';
export { default as Times } from './ico_times.svg';
export { default as Tools } from './ico_tools.svg';
export { default as Trash } from './ico_trash.svg';
export { default as Truck } from './ico_truck.svg';
export { default as UserCircle } from './ico_user-circle.svg';
export { default as User } from './ico_user.svg';
export { default as YoutubePlay } from './ico_youtube.svg';
export { default as Pipe } from './ico_pipe.svg';
export { default as XmarkCircleSolid } from './ico_xmark-circle--solid.svg';
